export default CookieConsentConfig = {
    guiOptions: {
        consentModal: {
            layout: "box wide",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        // functionality: {},
        analytics: {}
    },
    language: {
        default: "fr",
        translations: {
            en: {
                consentModal: {
                    title: "Cookie consent",
                    description: "Cookies are used on this site to improve site navigation and provide the greatest user experience. By clicking ”Accept all“, you consent to the use of the cookies.",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences",
                    // footer: "<a href=\"#link\">Privacy Policy</a>\n<a href=\"#link\">Terms and conditions</a>"
                },
                preferencesModal: {
                    title: "Privacy Preference Center",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    closeIconLabel: "Close modal",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Cookie Usage",
                            description: "Cookies are used on this site to improve site navigation and provide the greatest user experience. By clicking ”Accept all“, you consent to the use of the cookies."
                        },
                        {
                            title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                            description: "Strictly necessary cookies help make the site navigable by enabling basic functions such as page navigation and access to secure areas of the website. Without these cookies the website could not function properly.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytics Cookies",
                            description: "We use Google Analytics to measure audience and site statistics to understand how users interact with the site.",
                            linkedCategory: "analytics"
                        }
                    ]
                }
            },
            fr: {
                consentModal: {
                    title: "Nous utilisons des cookies",
                    description: "Les cookies sont utilisés sur ce site pour améliorer votre navigation et vou offrir la meilleure expérience utilisateur possible. En cliquant sur « Tout accepter », vous consentez à l'utilisation des cookies.",
                    acceptAllBtn: "Tout accepter",
                    acceptNecessaryBtn: "Tout rejeter",
                    showPreferencesBtn: "Gérer les préférences",
                    // footer: "<a href=\"#link\">Politique de confidentialité</a>\n<a href=\"#link\">Termes et conditions</a>"
                },
                preferencesModal: {
                    title: "Centre de préférence des cookies",
                    acceptAllBtn: "Tout accepter",
                    acceptNecessaryBtn: "Tout rejeter",
                    savePreferencesBtn: "Sauvegarder les préférences",
                    closeIconLabel: "Fermer la modale",
                    serviceCounterLabel: "Services",
                    sections: [
                        {
                            title: "Utilisation des Cookies",
                            description: "Les cookies sont utilisés sur ce site pour améliorer votre navigation et vou offrir la meilleure expérience utilisateur possible. En cliquant sur « Tout accepter », vous consentez à l'utilisation des cookies."
                        },
                        {
                            title: "Cookies strictement nécessaires <span class=\"pm__badge\">Toujours Activé</span>",
                            description: "Les cookies strictement nécessaires contribuent à rendre le site navigable en activant des fonctions de base telles que la navigation dans les pages et l'accès à des zones sécurisées du site Web. Sans ces cookies, le site Web ne pourrait pas fonctionner correctement.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Statistiques et audience",
                            description: "Nous utilisons Google Analytics afin de mesurer l'audiance et les statistiques du site afin de comprendre comment les utilisateurs interagissent avec le site.",
                            linkedCategory: "analytics"
                        }
                    ]
                }
            }
        }
    }
};