import EventEmitter from "eventemitter3";
import SiteMenu from "../components/site-menu";
import LocomotiveScroll from "../components/locomotive-scroll";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import components from "../components/_map";
import Utils from "../utils/utils";
import gsap from "gsap";
import { CustomEase } from "gsap/all";
import Dropdowns from "../components/dropdowns";
import barba from "@barba/core";
import utils from "../utils/utils";
gsap.registerPlugin(CustomEase);

export default class AppController extends EventEmitter {
	constructor() {
		super();

		// Handling singleton creation
		if (!AppController.singleton) {
			// Storing the singleton instance
			AppController.singleton = this;
			this.components = [];
			this.navVisible = true
			this.gsap = gsap
			this.currentController = document.body.getAttribute('data-controller');
			this.components_map = components;
			this.initBarba();
			// Returning the singleton
			this.init();
			this.$scroll = new LocomotiveScroll();
			this.$menu = new SiteMenu(this);
			this.$dropdowns = new Dropdowns();
			return AppController.singleton;
		}
	}
	init() {
		this.initAppEvents();
		this.initDomElements();
		this.instantiateComponents();
		this.initViewportVhVar();
		this.initDetect();
		this.initReservationOpeners();
		this.initTextareas();
		this.initButtonHover()

	}
	initDomElements() {
		this.$site_container = document.querySelector('.site-container');
		this.$navbar = document.querySelector(".c-site-menu__container");
		this.$footer = document.querySelector('.c-site-footer');
		this.$buttons = document.querySelectorAll('.c-button');

		this.$title = document.querySelectorAll('.st1,.st2,.st3')
		// this.$title.forEach(element => {
		// 	new SplitType(element, { types: 'line, words' })
		// 	gsap.to(element.querySelectorAll('.word'),{y:'100%',duration:0,ease: "0.14, 1.00, 0.34, 1.00"});
		// });
	}
	initAppEvents() {

		window.onhashchange = () => {
			this.emit("hash-change", window.location.href);
		};

		// Scroll events
		this.on("disable-scroll", () => {
			// disableBodyScroll(this.$site_container);
			if (this.$scroll) {
				this.$scroll.scroll.stop()
			}
		});
		this.on("enable-scroll", () => {
			// enableBodyScroll(this.$site_container);
			if (this.$scroll) {
				this.$scroll.enable()
			}
		});

		// Navbar events
		this.on("hide-navbar", () => {
			this.$navbar.classList.add('hidden');

		});
		this.on("show-navbar", () => {
			this.$navbar.classList.remove('hidden');
		});
		this.on('add-bg-navbar', () => {
			this.$navbar.classList.add('bg-white');
		})
		this.on('remove-bg-navbar', () => {
			this.$navbar.classList.remove('bg-white');
		})
	}
	initBarba() {
		barba.hooks.after(() => {
			this.$scroll.update();
			this.$scroll.scrollTop();
		});

		barba.hooks.afterLeave((data) => {
			const js = data.next.container.querySelectorAll("script.reload-on-ajax");
			[].forEach.call(js, function (script) {
				window.eval(script.innerHTML);
			});
		});

		barba.init({
			debug: true,
			timeout: 10000,
			cacheIgnore: true,
			prevent: ({ el }) => el.getAttribute('href') == '#' || el.getAttribute('href').includes('billet'),
			transitions: [
				{
					name: 'default',
					leave: (data) => {
						this.emit('close-menu')
						return new Promise(resolve => {
							this.emit('show-loader', resolve)
						});
					},
					enter: (data) => {
						this.$dropdowns.init();
						this.emit("remove-bg-navbar") 
						this.init();
						utils.setNewAttrs(data.next.html);

					}

				}
			]
		});
	}
	initReservationOpeners() {
		this.$reservationOpeners = document.querySelectorAll('.c-button.reservation');

		this.$reservationOpeners.forEach(button => {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				const eventId = button.getAttribute('data-event-id')
				this.emit('open-booking-popin', eventId)
			})
		});
	}
	initButtonHover() {
		this.$buttons.forEach(button => {
			button.addEventListener('mouseenter', () => {
				this.gsap.to(button.children, {
					keyframes: [
						{
							y: '-70%',
							autoAlpha: 1,
						}
						, {
							y: '-70%',
							autoAlpha: 0,
						}
						, {
							y: '50%',
							autoAlpha: 0,
						}
						, {
							y: 0,
							autoAlpha: 1,
						}
					],duration:1,ease:"0.90, 0.00, 0.10, 1.00"
				})
			})
		});
	}
	initTextareas() {
		const tx = document.getElementsByTagName("textarea");
		for (let i = 0; i < tx.length; i++) {
			tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
			tx[i].addEventListener("input", OnInput, false);
		}

		function OnInput() {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight) + "px";
		}
	}

	instantiateComponents() {
		const components = document.body.querySelectorAll("[data-component]");
		for (let i = 0; i < components.length; i++) {
			const component = components[i];
			const name = component.getAttribute("data-component");
			if (this.components_map.hasOwnProperty(name)) {
				const componentClass = this.components_map[name];
				if (componentClass) {
					this.components.push(new componentClass.default(component));
				}
			}
		}
	}

	initViewportVhVar() {
		this.updateViewportVhVar();
		if (Utils.deviceType() == 'desktop') {
			window.addEventListener("resize", () => {
				this.updateViewportVhVar()
			});
		}
	}
	updateViewportVhVar() {
		let vh = window.innerHeight;
		document.documentElement.style.setProperty('--vh', `${vh / 100}px`);
	}

	initDetect() {
		window.addEventListener("resize", () => {
			this.detectDevice();
		});
		this.detectDevice();
	}
	detectDevice = () => {
		let landscape = window.matchMedia("(orientation: landscape)");
		document.documentElement.setAttribute('data-device-notice', Utils.deviceType() == 'mobile' && landscape.matches && window.innerWidth < 1000);
	}

	static getInstance() {
		return AppController.singleton;
	}
}
