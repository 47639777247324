import AppController from "./controller/app-controller";

document.addEventListener("DOMContentLoaded", function () {
	const app = new AppController();
	setTimeout(() => {
        if (document.querySelector('.axeptio_mount')) {
            document.querySelector('.axeptio_mount').style.zIndex = "1" 
        } 
    }, 200);
});
